import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { getColorMode } from "./ColorMode";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from "axios";

const AdminPage = () => {
    const colorvar = getColorMode();
    const var1 = "-mode";
    const var2 = colorvar.concat(var1);

    const [resumes, setResumes] = useState([]);

    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/getAllResumes");
                setResumes(response.data);
            } catch (error) {
                console.error("Error fetching resumes:", error);
            }
        };

        fetchResumes();
    }, []);

    return (
        <>
        <div className={var2}>
            <Navbar />
            <div className="container">
                <h2 className="text-center text-black mb-4">Uploaded Resumes</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Resume Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Contact</th>
                            <th>Qualification</th>
                            <th>Skills</th>
                            <th>View Resume</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resumes.map((resume, index) => (
                            <tr key={index}>
                                <td>{resume.Resume_id}</td>
                                <td>{resume.name}</td>
                                <td>{resume.email}</td>
                                <td>{resume.company}</td>
                                <td>{resume.contact}</td>
                                <td>{resume.qualification}</td>
                                <td>{resume.skills}</td>
                                <td>
                                    <a href={resume.pdfUrl} target="_blank" rel="noopener noreferrer">
                                        View Resume
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </div>
        </>
    );
};

export default AdminPage;
