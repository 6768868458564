import React, { useEffect, useRef, useState } from "react";
import { getColorMode } from "../ColorMode";
import axios from "axios";
import "./Welcomeani.css";
import Waves from "./bubbleanima";

const CanvasAnimation = () => {
  const canvasRef = useRef(null);
  const numPoints = 50;
  let aPoints = [];

  useEffect(() => {
    const can = canvasRef.current;
    const ctx = can.getContext("2d");

    let width = window.innerWidth;
    let height = window.innerHeight;

    // Resizing the canvas
    can.width = width;
    can.height = height;

    const handleResize = () => {
      width = window.innerWidth;
      height = window.innerHeight;
      can.width = width;
      can.height = height;
    };

    window.addEventListener("resize", handleResize);

    class Point {
      constructor() {
        this._size = 0.5;
        this._x = 0;
        this._y = 0;
        this._direction = 0;
        this._velocity = 0;
        this._distances = [];
        this._neighboors = [];
        this._randomization = 0;
        this.__collection = null;
      }

      _step(aCollection) {
        const modifiedVelocity = this._velocity * 1 * ((Math.random() * this._randomization + 1) / 10);
        const direction = Math.random() * 2 % 2 > 1 ? -1 : 1;
        this._direction = this._direction * 1 + (Math.random() * this._randomization) * direction;
        const radianAngle = (this._direction * Math.PI) / 180;

        this._x = this._x * 1 + modifiedVelocity * Math.cos(radianAngle) * 1;
        this._y = this._y * 1 + modifiedVelocity * Math.sin(radianAngle) * 1;

        if (this._x > width) this._x = 0;
        if (this._x < 0) this._x = width;
        if (this._y > height) this._y = 0;
        if (this._y < 0) this._y = height;

        this.__collection = aCollection;
      }

      _computeNeighboors() {
        if (this.__collection == null) return;
        const aCollection = this.__collection;
        this._distances = [];
        for (let i = 0; i < aCollection.length; i++) {
          if (aCollection[i]._x !== this._x && aCollection[i]._y !== this._y) {
            this._distances.push({
              pointIndex: i,
              pointObj: aCollection[i],
              distance: Math.sqrt(Math.pow(this._x - aCollection[i]._x, 2) + Math.pow(this._y - aCollection[i]._y, 2)),
            });
          }
        }
        this._distances.sort((a, b) => (a.distance < b.distance ? -1 : 1));
        this._neighboors = this._distances.slice(0, 3);
      }

      draw(context) {
        this._computeNeighboors();

        context.lineWidth = 0.5;
        context.strokeStyle = "#666";
        context.beginPath();
        for (let i = 0; i < this._neighboors.length; i++) {
          context.moveTo(this._x, this._y);
          context.lineTo(this._neighboors[i].pointObj._x, this._neighboors[i].pointObj._y);
          context.lineWidth = 0.10 + 5 / this._neighboors[i].distance;
        }
        context.closePath();
        context.stroke();

        context.beginPath();
        context.arc(this._x, this._y, this._size * this._velocity, 0, 2 * Math.PI, false);
        context.fillStyle = "#4430c7";
        context.strokeStyle = "#c78d30";
        context.lineWidth = 0.5;
        context.fill();
        context.stroke();

        context.beginPath();
        context.arc(this._x, this._y, this._size, 0, 2 * Math.PI, false);
        context.fillStyle = "#c730c2";
        context.fill();
      }
    }

    const initPoints = () => {
      for (let x = 0; x < numPoints; x++) {
        const newPoint = new Point();
        newPoint._size = (Math.random() * (3 - 0.5) + 0.5).toFixed(2);
        newPoint._x = (Math.random() * width).toFixed(0);
        newPoint._y = (Math.random() * height).toFixed(0);
        newPoint._direction = (Math.random() * 360).toFixed(2);
        newPoint._velocity = (Math.random() * (2 - 0.1) + 0.2).toFixed(2);
        newPoint._randomization = (Math.random() * (10 - 0) + 0).toFixed(2);
        aPoints.push(newPoint);
      }
    };

    const animate = () => {
      for (let x = 0; x < numPoints; x++) {
        aPoints[x]._step(aPoints);
      }
      requestAnimationFrame(animate);
      draw();
    };

    const draw = () => {
      ctx.save();
      ctx.clearRect(0, 0, width, height);
      for (let x = 0; x < numPoints; x++) {
        aPoints[x].draw(ctx);
      }
      ctx.restore();
    };

    initPoints();
    animate();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [numPoints]);

  const colorMode = getColorMode();

  const var1 = "-welcome-text";
  const var2 = colorMode.concat(var1);

  const var3 = "-welcome-area";
  const var4 = colorMode.concat(var3);

  const var5 = "-welcomediv";
  const var6 = colorMode.concat(var5);

  const [data, setData] = useState('');
  const vantaRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/welcome");
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  }, []);

  return (
   
      <div className={var6} id="welcomediv">
       
        <canvas id="playArea" ref={canvasRef}/>
       
        <div className={`${var4} welcome-area`} id="home" style={{ paddingTop: "150px", marginLeft: "35%" }}>
          <div className="container3">
            <div className="row">
              <div className="col-12 col-md-7 ">
                <div className="welcome-right">
                 
                  <div className={var2}>
                    <h1 style={{ fontSize: "32px" }}>{data.title}</h1>
                    <h4 style={{ fontSize: "15px" }}>{data.description}</h4>
                  </div>
                  <div className="welcome-btn">
                    <a href="#" className="gradient-btn v2 mr-20">
                      Register
                    </a>
                    <a href="#" className="gradient-btn v2">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        {/* <Waves/> */}
      </div>
  
  );
};

export default CanvasAnimation;