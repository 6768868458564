import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { getColorMode } from "./ColorMode";
import Contact from "./Components/contact";


const Contactpage=()=>{

    // useEffect(()=>{
    //     document.title="Contact | Cogniglob"
    //   },[]);
    

    const colorvar= getColorMode();


    const var1="-mode";
    const var2=colorvar.concat(var1);
    return(
        <div className={var2}>
            <Navbar />
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Contactpage;