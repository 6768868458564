import React,{useState, useEffect} from "react";
import image3 from "../assets/img/c-logo-1.png";
import image4 from "../assets/img/c-logo-2.png";
import image5 from "../assets/img/c-logo-3.png";
import image6 from "../assets/img/c-logo-4.png";
import image7 from "../assets/img/c-logo-5.png";
import image8 from "../assets/img/about-left.png";
import axios from "axios";
import { getColorMode } from "../ColorMode";

const About=()=>{

  const colorMode = getColorMode();

  const var1="-about-area";

  const var2 = colorMode.concat(var1);
  
  const[data,setData]= useState('');

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/about");
        console.log('Fetched doctor data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

    return(
        <div className="about-area wow fadeInUp" id="about">
   
    <div className="container">
     
     
      <div className="row aboutalign">
        <div className="col-12 col-md-6">
          <div className="about-mid-img">
            <img src={data.image} alt="" />
          </div>
        </div>
        <div className={`${var2} about-areadiv col-12 col-md-6 align-self-center about-mid-text`}>
          <div className="heading">
            <h5>{data.title}</h5>
          </div>
          <div className="about-mid-text" style={{textAlign:"justify"}}>
            <h1>{data.description}</h1>
            <div className="space-10" />
            <p>
              {data.sub_title}
            </p>
          </div>
          <div className="space-30" />
          {/* <a href="#" className="gradient-btn v2 about-btn">
            {" "}
            <i className="fa fa-send-o" /> join us on telegraph
          </a> */}
          <div className="space-10"/>
        </div>
      </div>
    </div>
    <div className="space-90" />
  </div>
    );
}

export default About;