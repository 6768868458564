import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "./bootstrap.min.css";
import "./animation.css";
import Navbar from "./Components/Navbar";
import "./style.css";
import Welcome from "./Components/Welcome";
import About from "./Components/about";
import Singleabout from "./Components/Single-about";
import IcoLive from "./Components/Icolive";
import Documentation from "./Components/Documentation";
import DistributionArea from "./Components/DistributionArea";
import Roadmap from "./Components/Roadmap";
import CoreTeam from "./Components/Team";
import Footer from "./Components/Footer";
import Community from "./Components/Community";
import FAQ from "./Components/FAQ";
import MobileApp from "./Components/MobileApp";
import "./responsive.css";
import "./animate.min.css";
import { getColorMode } from "./ColorMode";
import Loader from "./Components/Loader";
import Team from "./Components/Team";
import CanvasAnimation from "./Components/Welcomeani";
import Waves from "./Components/bubbleanima";
import AnimLoader from "./Components/animloader";
import Demo from "./Components/demo";

const Homepage=()=>{

  useEffect(()=>{
    document.title="Home | Cogniglob"
  },[]);


  const colorvar= getColorMode();


  const var1="-mode";
  const var2=colorvar.concat(var1);

  const var3="-welcomenav";
  const var4=colorvar.concat(var3);

    return(
      <>
      
      <div className={var2}>
      
  <title>Cyptian</title>
  {/* Required meta tags */}
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  {/* carousel CSS */}
  <link rel="stylesheet" href="assets/css/owl.carousel.min.css" />
  {/*header icon CSS */}
  <link rel="icon" href="./assets/img/fabicon.png" />
  {/* animations CSS */}
  <link rel="stylesheet" href="assets/css/animate.min.css" />
  {/* font-awsome CSS */}
  <link rel="stylesheet" href="assets/css/font-awesome.min.css" />
  {/* Bootstrap CSS */}
  <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
  {/* mobile menu CSS */}
  <link rel="stylesheet" href="assets/css/slicknav.min.css" />
  {/*css animation*/}
  <link rel="stylesheet" href="assets/css/animation.css" />
  {/*css animation*/}
  <link
    rel="stylesheet"
    href="assets/css/material-design-iconic-font.min.css"
  />
  {/* style CSS */}
  <link rel="stylesheet" href="style.css" />
  {/* responsive CSS */}
  <link rel="stylesheet" href="assets/css/responsive.css" />
  <div className={`${var4}`}>
    <Navbar/>
  
    <CanvasAnimation/>
  </div>
  {/* <Waves/> */}
  {/* <Welcome/> */}
  <About/>
  <Demo/>
  <div className="team-bg">
    
  </div>
  {/* <Team/> */}
  <Footer/>
</div>
</>
    );
}

export default Homepage;