import React,{useState, useEffect} from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { getColorMode } from "./ColorMode";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from "axios";

const ResumeUploader=()=>{

    const colorvar= getColorMode();


    const var1="-mode";
    const var2=colorvar.concat(var1);

    const [name, setName] = useState('');
    const [qualification, setQualification] = useState('');
    const [company, setCompany] = useState('');
    const [skills, setSkills] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [pdfFile, setPdfFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); 
    const [pdfUrl, setPdfUrl] = useState('');


    useEffect(() => {
        const user = firebase.auth().currentUser;
        if (user) {
            setEmail(user.email);
            fetchUserData(user.email);
        }
    }, []);

    const fetchUserData = async (userEmail) => {
        try {
          const response = await axios.get(`https://us-central1-cogniglob-website1.cloudfunctions.net/api/getUserResume?email=${userEmail}`);
          const userData = response.data;
      
          if (userData) {
            setName(userData.name || '');
            setQualification(userData.qualification || '');
            setCompany(userData.company || '');
            setSkills(userData.skills || '');
            setContact(userData.contact || '');
            setPdfUrl(userData.pdfUrl || '');
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.error("Resume not found for the given email.");
            setErrorMessage("Resume not found. Please upload a new one.");
          } else {
            console.error("Error fetching user data:", error);
            setErrorMessage("There was an error fetching your resume. Please try again later.");
          }
        }
      };
      

    const handleFileChange = (e) => {
        setPdfFile(e.target.files[0]);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        // Check if PDF file is selected
        if (!pdfFile) {
            setErrorMessage("Please upload a PDF resume.");
            return;
        }
    
        try {
            // Upload PDF file to Firebase Storage
            const storageRef = firebase.storage().ref();
            const pdfRef = storageRef.child(`resumes/${pdfFile.name}`);
            await pdfRef.put(pdfFile);
            const pdfUrl = await pdfRef.getDownloadURL();
    
            // Prepare form data
            const formData = {
                name,
                qualification,
                company,
                skills,
                email,
                contact,
                pdfUrl, // Include PDF URL in form data
            };
    
            // Send the form data to the backend for upload or update
            const response = await axios.post("https://us-central1-cogniglob-website1.cloudfunctions.net/api/uploadResume", { email, formData });
            
            // Check the response
            setSuccessMessage(response.data.message);
            setErrorMessage(""); // Clear any previous error messages
    
        } catch (error) {
            console.error("Error submitting form:", error);
            setErrorMessage("There was an error sending your message. Please try again.");
        }
    };
    
    

    return(
        <div className={var2}>
            <Navbar/>
                <div className="container" style={{marginTop:"10px"}}>
                    <div className="row justify-content-center mb-5 pb-5">
                        <div className="col-md-7 text-center heading-section ftco-animate">
                            <h2 className="mb-2 text-black">Resume Uploader</h2>
                        </div>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                        <div className="row">
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="Name" className="form-label">Name:</label>
                                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="Qualification" className="form-label">Qualification:</label>
                                <input type="text" id="qualification" value={qualification} onChange={(e) => setQualification(e.target.value)} className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="currentCompany" className="form-label">Current Company:</label>
                                <input type="text" id="currentCompany" value={company} onChange={(e) => setCompany(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="skills" className="form-label">Skills:</label>
                                <input type="text" id="skills" value={skills} onChange={(e) => setSkills(e.target.value)} className="form-control" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" />
                            </div>
                            <div className="col-sm-6 mb-3">
                                <label htmlFor="contact" className="form-label">Contact Number:</label>
                                <input type="text" id="contact" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control" />
                            </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <label htmlFor="pdfFile" className="form-label">Upload Resume (PDF):</label>
                            <input type="file" id="pdfFile" onChange={handleFileChange} accept="application/pdf" className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label>Uploaded PDF:</label>
                            {pdfUrl && (
                                <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                                    View Uploaded Resume
                                </a>
                            )}
                        </div>
                        <button type="submit" className="btn btn-primary" style={{textAlign:"center",width:"200px"}}>Submit</button>
                    </form>
                </div>
            <Footer/>
        </div>

    );
}

export default ResumeUploader;