import React, { useState, useEffect } from "react";
import image1 from "../assets/img/Cogniglob2.png";
import { getColorMode } from "../ColorMode";
import firebase from 'firebase/compat/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Import faChevronDown for dropdown icon
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [headerClass, setHeaderClass] = useState("");
  const [headerClass1, setHeaderClass1] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobile menu state
  const [isServiceDropdownOpen, setIsServiceDropdownOpen] = useState(false); // Mobile service dropdown state
  const navigate = useNavigate();

  const colorvar = getColorMode();
  const var1 = "-text";
  const var2 = colorvar.concat(var1);

  const var3 = "-header-area";
  const var4 = colorvar.concat(var3);

  const var5 = "-background-header";
  const var6 = colorvar.concat(var5);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 10) {
        setHeaderClass(`${var6} background-header header-reduced `);
      } else {
        setHeaderClass("navbarheadtitle");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 10) {
        setHeaderClass1("");
      } else {
        setHeaderClass1("mobile-iconcolor");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = () => {
    firebase.auth().signOut().then(() => {
      setIsLoggedIn(false);
      navigate("/");
    });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu
  };

  const toggleServiceDropdown = () => {
    setIsServiceDropdownOpen(!isServiceDropdownOpen); // Toggle service dropdown
  };

  return (
    <div className={`${var4} header-area wow fadeInDown header-absolate ${headerClass}`}>
      <div className="container">
        <div className="row align-items-center">
          {/* Logo */}
          <div className="col-4 col-lg-2">
            <div className="logo-area">
              <a href="#">
                <img src={image1} alt="Logo" />
              </a>
            </div>
          </div>

          {/* Mobile Menu Icon (Right-Aligned) */}
          <div className="col-8 d-lg-none text-right">
            <FontAwesomeIcon
              icon={faBars}
              className={`mobile-menu-icon`}
              onClick={toggleMobileMenu}
            />
          </div>

          {/* Desktop Navigation */}
          <div className="col-lg-8 d-none d-lg-block">
            <nav className={`${var2} main-menu text-center`}>
              <ul id="slick-nav">
                <li><a className="scroll high1" href="/">Home</a></li>
                <li><a className="scroll high2" href="/about">About</a></li>
                <li className="dropdown3">
                  <a className="scroll high3" href="#">Service</a>
                  <ul className="dropdown-menu2">
                    <li><a className="navdrop" href="/service/1">Cloud Migration</a>
                    <a className="navdrop" href="/service/2">Consulting & Implementation</a>
                    <a className="navdrop" href="/service/3">Upgrades</a>
                    <a className="navdrop" href="/service/4">Managed Services</a>
                    <a className="navdrop" href="/service/5">Integrated Services</a>
                    <a className="navdrop" href="/service/6">Product Development</a>
                    <a className="navdrop" href="/service/7">Infrastructure Services</a></li>
                  </ul>
                </li>
                <li><a className="scroll high4" href="/contact">Contact</a></li>
              </ul>
            </nav>
          </div>

          <div className="col-lg-2 text-right d-none d-lg-block">
            {isLoggedIn ? (
              <div className="user-menu">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div id="userDropdown" className="dropdown-menu1">
                  <a href="/profile">Profile</a>
                  <a href="/seo">SEO</a>
                  <a href="#" onClick={handleLogout}>Logout</a>
                </div>
              </div>
            ) : (
              <a href="/login" className="loginbtn gradient-btn">Login</a>
            )}
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className="mobile-nav">
            <nav>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li>
                  <a href="#" onClick={toggleServiceDropdown}>
                    Services <FontAwesomeIcon icon={faChevronDown} />
                  </a>
                  {isServiceDropdownOpen && (
                    <ul className="dropdown1">
                      <li><a href="/service/1">Cloud Migration</a></li>
                      <li><a href="/service/2">Consulting & Implementation</a></li>
                      <li><a href="/service/3">Upgrades</a></li>
                      <li><a href="/service/4">Managed Services</a></li>
                      <li><a href="/service/5">Integrated Services</a></li>
                      <li><a href="/service/6">Product Development</a></li>
                      <li><a href="/service/7">Infrastructure Services</a></li>
                    </ul>
                  )}
                </li>
                <li><a href="/contact">Contact</a></li>
                {!isLoggedIn && (
                  <li className="mobile-login-btn">
                    <a href="/login" className="gradient-btn">Login</a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
