import React,{useState, useEffect} from "react";
import image1 from "../assets/img/Cogniglob2.png";
import { getColorMode } from "../ColorMode";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Addressgif from "../images/google-maps-unscreen.gif";
import emailgif from "../images/email-unscreen.gif";
import callgif from "../images/call-unscreen.gif";

const Footer=()=>{
  const colorvar= getColorMode();


  const var1="-single-footer";
  const var2=colorvar.concat(var1);

  const[data,setData]= useState('');

  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await axios.get("https://us-central1-cogniglob-website1.cloudfunctions.net/api/footerdes");
        console.log('Fetched doctor data:', response.data); 
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
      }
    };
    fetchData();
  },[]);

  

    return(
      <footer>
        <div className="footer-area section-padding wow fadeInDown">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
              <div className={`${var2} single-footer`}>
                <div className="logo-area footer">
                  <a href="#">
                    <img src={image1} alt="" />
                  </a>
                </div>
                {/* <div className="space-20" /> */}
                <p>
                 {data.Description}
                </p>
                <div className="space-10" />
                <div className="social-container">
                <ul className="social-icons">
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                </ul>
              </div>


                {/* <p>
            
                  Copyright © All rights reserved | This template is made with{" "}
                  <i className="fa fa-heart-o" aria-hidden="true" /> by{" "}
                  <a href="https://www.datadna.in/" target="_blank">
                    DATADNA
                  </a>
                 
                </p> */}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={`${var2} single-footer`}>
                <div className="space-10" />
                <h4>Quick Links</h4>
                <div className="space-10" />
                <ul className="quick-links">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/service">Service</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3">
              <div className={`${var2} single-footer`}>
                <div className="space-10" />
                <h4>
              Address</h4>
                <div className="space-10" />
                <ul className="address-list">
                  <li> <img 
                src={Addressgif} 
                alt="Address Icon" 
                style={{ width: '30px', marginRight: '5px' }} 
              />
                  No.121
                  </li>
                  <li className="addressre">
                  Gomathi Amman Nagar Athivakkam,
                  </li>
                  <li className="addressre">
                  Redhills, Tiruvallur,
                  </li>
                  <li className="addressre">
                  Ponneri, Tamil Nadu,
                  </li>
                  <li className="addressre">
                  India, 600052
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-1">
                <div className={`${var2} single-footer`}>
                  <div className="space-10" />
                  <h4>Contact</h4>
                  <div className="space-10" />
                  <ul className="linkcon">
                    <li>
                      <img
                        src={emailgif}
                        alt="Email Icon"
                        style={{ width: '30px', marginRight: '5px' }}
                      />
                      info@cogniglob.com
                    </li>
                  </ul>
                  <ul className="linkcon">
                    <li>
                      <img
                        src={callgif}
                        alt="Phone Icon"
                        style={{ width: '30px', marginRight: '5px' }}
                      />
                      +919940190129
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className={`${var2} single-footer`}>
                <ul>
                  <li>
                    <a href="#">White Paper</a>
                  </li>
                  <li>
                    <a href="#">Team</a>
                  </li>
                  <li>
                    <a href="#">APP</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="single-footer">
                <p>Subscribe to our Newsletter</p>
                <div className="space-20" />
                <div className="footer-form">
                  <form action="#">
                    <input type="email" placeholder="Email Address" />
                    <a href="" className="gradient-btn subscribe">
                      GO
                    </a>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      </footer>
    );
}

export default Footer;